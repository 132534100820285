'use client';

import { GTM } from '@atomicleads/ext-satellite-form';
import { isProduction } from '@/utils/constants';

const gtmMeasurementID = process.env.NEXT_PUBLIC_GTM_MEASUREMENT_ID;

export const GoogleTagManager = () => {
  if (!gtmMeasurementID || !isProduction) return null;

  return <GTM gtmMeasurementID={gtmMeasurementID} domain={'https://sgtm.irs-extension.online'} />;
};
