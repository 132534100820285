'use client';

import React, { ComponentProps, ReactNode, Suspense, useEffect } from 'react';
import { Funnel, GoogleOneTap, PostHogPageview, SatelliteFormProvider } from '@atomicleads/ext-satellite-form';
import axios from 'axios';
import Router from 'next/router';
import Link from 'next/link';
import Image from 'next/image';
import { getCookies, setCookie } from 'cookies-next';
import { GTMPageView } from '../utils/gtm';
import { ipEvent } from '../utils/events';
import { OntraInit } from '../utils/ontraport';
import { PostHogProvider } from 'posthog-js/react';
import { posthog } from 'posthog-js';
import { PostHogConfig } from 'posthog-js';
import { getPosthogSearchParamOverrides } from '@/posthog/config';
import { Globals } from '@/providers/Globals';
import { Footer as FooterType, Header as HeaderType, NotFound as NotFoundType } from '@/payload-types';
import { StickyButtonProvider } from '@/providers/StickyButton';

type SatelliteFormProviderProps = ComponentProps<typeof SatelliteFormProvider>;
const clientId = process.env.NEXT_PUBLIC_GOOGLE_CLIENT_ID as string;
type ProductFocus = Parameters<typeof GoogleOneTap>[0]['productFocus'];

export const Providers = ({
  children,
  basicMode,
  header,
  footer,
  generationDate,
  notFoundContent
}: {
  children: ReactNode;
  basicMode?: boolean;
  header: HeaderType;
  footer: FooterType;
  notFoundContent: NotFoundType;
  generationDate: string;
}) => {
  useEffect(() => {
    const options: Partial<PostHogConfig> = {
      api_host: process.env.NEXT_PUBLIC_POSTHOG_HOST
    };

    const fetchPosthogConfig = () => {
      const searchParams = Object.fromEntries(new URL(window.location.href)?.searchParams);

      if (Object.keys(searchParams).length) {
        options.bootstrap = {
          featureFlags: searchParams
        };
      }
      posthog.init(process.env.NEXT_PUBLIC_POSTHOG_KEY as string, options);

      posthog.people.set({
        $current_url: window.location.href
      });

      const overrides = getPosthogSearchParamOverrides(searchParams);
      posthog.featureFlags.override(overrides);
    };

    fetchPosthogConfig();

    if (!basicMode) {
      OntraInit();
      const searchParams = Object.fromEntries(new URL(window.location.href).searchParams);

      const currentCookies = getCookies() as Record<string, string>;
      for (const a in searchParams) {
        if (!(a in currentCookies) || !currentCookies[a] || currentCookies[a] === '') {
          setCookie('irs-' + a, searchParams[a], {
            maxAge: 30 * 24 * 60 * 60,
            path: '/'
          });
        }
      }

      if (typeof window !== 'undefined' && searchParams) {
        if ('rtkcmpid' in searchParams) {
          setCookie('rtkcmpid', searchParams['rtkcmpid'], {
            maxAge: 30 * 24 * 60 * 60,
            path: '/'
          });
        }
      }
    }
  }, []);

  useEffect(() => {
    const handleRouteChange = (url: string) => {
      console.log('Route change completed');
      GTMPageView(url);
      ipEvent().then();
    };

    Router.events.on('routeChangeComplete', handleRouteChange);
    ipEvent().then();

    return () => {
      console.log('disabling events');
      Router.events.off('routeChangeComplete', handleRouteChange);
    };
  }, []);

  return (
    <Globals
      globals={{
        header,
        footer,
        generationDate,
        notFoundContent
      }}
    >
      <SatelliteFormProvider
        axios={axios}
        Router={Router as unknown as SatelliteFormProviderProps['Router']}
        Link={Link as SatelliteFormProviderProps['Link']}
        Image={Image as SatelliteFormProviderProps['Image']}
        theme={'satellite'}
      >
        <StickyButtonProvider>
          <GoogleOneTap clientId={clientId} productFocus={'extension' as ProductFocus} />

          {
            <PostHogProvider client={posthog}>
              <Suspense fallback={<></>}>
                <PostHogPageview />
                <Funnel />
              </Suspense>
              {children}
            </PostHogProvider>
          }
        </StickyButtonProvider>
      </SatelliteFormProvider>
    </Globals>
  );
};
